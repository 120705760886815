body {
  color: white;
  background-color: black;
}

.header {
  position: fixed;
  width: 100vw;
  background-color: black;

  nav {
    display: flex;
    align-items: center;
    padding-left: 20px;

    .logo {
      display: flex;
      align-items: center;

      .catsu-coin-logo {
        height: 40px;
      }

      span {
        padding-left: 10px;
        font-weight: bold;
        font-size: 30px;
      }
    }
  }
}

.hero {
  display: flex;
  text-align: center;
  height: 800px;
  padding-top: 40px;

  background-image: url('assets/catsu_hero_bg_extended.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  .catsulogo {
    width: 500px;
  }

  .hero-title {
    position: absolute;
    top:60%;
    left: 35%;
    font-size: 60px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8); /* Add shadow for better readability */
  }
}

.what-is {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px; /* Space between sections */
  padding: 100px;

  h1 {
    margin: 0;
    font-size: 45px;
    font-weight: 700;
  }

  .subheader {
    margin-top: 20px;
    font-size: 20px;
    line-height: 2;
  }

  p {
    margin-top: 20px;
    line-height: 1.8;
  }

  .text-section {
    flex: 1; /* Takes up equal space */
    padding: 20px;
  }

  /* Style for image section */
  .image-section {
    flex: 1; /* Takes up equal space */
  }

  .image-section img {
    width: 100%;
    height: auto;
    border-radius: 8px; /* Optional, adds rounded corners to the image */
  }
}